import gql from 'graphql-tag'

export const SEOField = gql`
  fragment SEOField on NewSEOFieldType {
    id
    url
    headTitle
    metaDescription
    linkRelCanonical
    ogImage
    seoText
  }
`
