import gql from 'graphql-tag'
import { CollectionInNav } from '~/fragments/CollectionInNavType'
import { SEOField } from '~/fragments/SEOFieldType'
import { TypeOfCollection } from '~/fragments/TypeOfCollectionType'

export const GlobalData = gql`
  ${SEOField}
  ${CollectionInNav}
  ${TypeOfCollection}
  fragment GlobalData on GlobalDataType {
    mainNavCollectionTypes {
      ...TypeOfCollection
    }
    mainNavFeaturedCollections {
      ...CollectionInNav
    }
    seoFields {
      ...SEOField
    }
    canonical
    relPrev
    relNext
    loginBanner
    signupBanner
  }
`
